// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  min-height: max(11vh, 100px);
  max-width: 100vw;
  margin: auto;
  
  padding: 0;
  font-family: 'Montserrat', monospace;


  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 

  
  --white: #fafafa;
  --red: #fe0222;

  --background: green;
  --accent: black;
}

body::-webkit-scrollbar {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/css/index.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,YAAY;;EAEZ,UAAU;EACV,oCAAoC;;;EAGpC,kBAAkB;EAClB,kBAAkB;EAClB,wBAAwB,GAAG,gBAAgB;EAC3C,qBAAqB;;;EAGrB,gBAAgB;EAChB,cAAc;;EAEd,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;AACf","sourcesContent":["body {\n  min-height: max(11vh, 100px);\n  max-width: 100vw;\n  margin: auto;\n  \n  padding: 0;\n  font-family: 'Montserrat', monospace;\n\n\n  overflow-y: scroll;\n  overflow-x: hidden;\n  -ms-overflow-style: none;  /* IE and Edge */\n  scrollbar-width: none; \n\n  \n  --white: #fafafa;\n  --red: #fe0222;\n\n  --background: green;\n  --accent: black;\n}\n\nbody::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
