// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiListItemText-root.headerButton, .MuiButton-root.headerButton {
  color: #8dc63f;
  font-size: larger;
  font-family: 'Montserrat';
  font-weight: bold;
  border: none;
  background-color: transparent;
  margin-right: 20px;
  width: max-content;
  text-transform: capitalize;
}

.MuiListItemText-root::first-letter, .MuiButton-rootroot::first-letter {
  text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./src/css/header.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,yBAAyB;EACzB,iBAAiB;EACjB,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB;EAClB,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".MuiListItemText-root.headerButton, .MuiButton-root.headerButton {\n  color: #8dc63f;\n  font-size: larger;\n  font-family: 'Montserrat';\n  font-weight: bold;\n  border: none;\n  background-color: transparent;\n  margin-right: 20px;\n  width: max-content;\n  text-transform: capitalize;\n}\n\n.MuiListItemText-root::first-letter, .MuiButton-rootroot::first-letter {\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
