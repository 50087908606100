import { useState } from "react";
import {
  AppBar,
  Box,
  CardMedia,
  Toolbar,
  IconButton,
  Button,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

import "../../css/header.css";

import { useTranslation } from "react-i18next";

const updateLangPath = (lang) => {
  if (lang === "en-US") {
    return "en";
  }

  if (lang === "uk-UA") {
    return "ua";
  }

  return lang;
};

// pages list
const GetPages = () => {
  const { t, i18n } = useTranslation();

  const lang = updateLangPath(i18n.language);
  return [
    {
      title: t("header.menu.about"),
      link: `/${lang}/about`,
    },

    {
      title: t("header.menu.partners"),
      link: `/${lang}/partners`,
    },
    {
      title: t("header.menu.projects"),
      link: `/${lang}/projects`,
    },
    {
      title: t("header.menu.contacts"),
      link: `/${lang}/contacts`,
    },
  ];
};

const languageSettings = [
  {
    key: "ua",
    title: "UA",
  },
  {
    key: "en",
    title: "ENG",
  },
];

function languageButton(key, title, handleChangeLanguageMenu) {
  return (
    <Button
      color="inherit"
      onClick={handleChangeLanguageMenu}
      value={key}
      size={"large"}
      sx={{
        color: "#8dc63f",
        fontFamily: "Montserrat",
        fontWeight: "bolder",
        fontSize: {
          xs: "0.8em",
          sm: "0.8em",
          md: "0.6em",
          lg: "0.6em",
          xl: "0.6em",
        },
      }}
    >
      {title}
    </Button>
  );
}

function uakImage() {
  return (
    <Button
      sx={{
        maxHeight: "11vh",
        minHeight: "100px",
        aspectRatio: "2/1",
        scale: "1",
      }}
      href="/"
    >
      <CardMedia
        component="img"
        src={"/images/header/uakLogo.png"}
        sx={{
          objectFit: "cover",
          height: "100%",
        }}
      />
    </Button>
  );
}

function Header({ onLanguageChange }) {
  const [open, setOpen] = useState(false);

  const handleChangeLanguageMenu = (val) => {
    onLanguageChange(val.currentTarget.value);
  };

  const pages = GetPages();

  const { i18n } = useTranslation();

  const drawer = () => {
    return (
      <Drawer
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "15vw",
            minWidth: "200px",
            zIndex: "9999",
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <List>
          {pages.map((nav, index) => (
            <ListItem
              key={index}
              component={Link}
              to={nav.link}
              onClick={() => setOpen(false)}
            >
              <ListItemText disableTypography className="headerButton">
                {nav.title}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>
    );
  };

  return (
    <AppBar
      sx={{
        width: "100vw",
        height: "11vh",
        minHeight: "100px",
        left: 0,
      }}
      color="default"
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box
          sx={{
            display: {
              xs: "flex",
              xl: "none",
            },
          }}
        >
          <IconButton
            size="large"
            onClick={() => setOpen(true)}
            sx={{
              color: "#8dc63f",
              maxHeight: "75px",
              maxWidth: "75px",
            }}
          >
            <MenuIcon
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                },
                color: "#8dc63f",
                width: "auto",
                height: "50px",
              }}
            />

            <MenuIcon
              sx={{
                display: {
                  xs: "flex",
                  sm: "none",
                },
                color: "#8dc63f",
                width: "auto",
              }}
            />
          </IconButton>
          {drawer()}
        </Box>
        {uakImage()}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", xl: "flex", justifyContent: "right" },
          }}
        >
          {pages.map((nav, index) => (
            <Button
              className="headerButton"
              key={index}
              onClick={() => setOpen(false)}
              href={nav.link}
            >
              <Typography
                sx={{
                  fontSize: { xs: "1em", md: "1.2em" },
                  fontWeight: "bold",
                }}
              >
                {nav.title}
              </Typography>
            </Button>
          ))}
        </Box>

        <Box
          sx={{
            displa: "flex",
            flexDirection: "row",
            color: "#8dc63f",
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: {
              xs: "1em",
              sm: "1.2em",
              md: "2em",
              lg: "2em",
              xl: "2em",
            },
          }}
        >
          <Toolbar
            sx={{
              marginTop: "1vh",
              display: { xs: "none", md: "inherit" },
            }}
          >
            {languageSettings[0].key === "ua"}
            {languageButton(
              languageSettings[0].key,
              languageSettings[0].title,
              handleChangeLanguageMenu
            )}
            |
            {languageButton(
              languageSettings[1].key,
              languageSettings[1].title,
              handleChangeLanguageMenu
            )}
          </Toolbar>

          <Box
            sx={{
              display: { xs: "inherit", md: "none" },
            }}
          >
            {i18n.language === "ua"
              ? languageButton(
                  languageSettings[1].key,
                  languageSettings[0].title,
                  handleChangeLanguageMenu
                )
              : languageButton(
                  languageSettings[0].key,
                  languageSettings[1].title,
                  handleChangeLanguageMenu
                )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default Header;
