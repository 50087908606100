import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState, lazy, Suspense } from "react";
import Header from "./components/general/Header";
import { FooterWrapper } from "./components/general/Footer";
import EventDescriptionScroll from "./pages/posts/description";
import { GetOneEvent } from "./services/api/pages/pages";

import { FullSrcLoader } from "./components/loader/pageLoader";
import { OOPSError } from "./components/error/oops/page";
import { SEO } from "./components/seo/seo";
import "./css/app.css";

const MainPage = lazy(() => import("./pages/main/MainPage"));
const Projects = lazy(() => import("./pages/posts/Projects"));
const ContactForm = lazy(() => import("./pages/contacts/ContactForm"));
const Partners = lazy(() => import("./pages/partners/Partners"));
const About = lazy(() => import("./pages/about/About"));

async function GetMainPageProjectDescr(pageID, language) {
  const response = await GetOneEvent({
    params: {
      lang: language,
      id: pageID,
    },
  }).catch((error) => {
    console.error(error);

    return null;
  });

  if (response !== undefined) {
    if (response.data !== null) {
      return response.data;
    }
  }
  return null;
}

const updateLangPath = (lang) => {
  if (lang === "en-US") {
    return "en";
  }

  if (lang === "uk-UA") {
    return "ua";
  }

  return lang;
};

const replaceString = (text) => {
  const newText = text.replace(/-/g, "/");
  return newText;
};

const App = () => {
  const { i18n, ready } = useTranslation();
  const navigate = useNavigate();

  const [fullEventData, setFullEventData] = useState(null);
  const [projectDescr, setProjectDescr] = useState(null);
  const [error, setError] = useState(false);
  const newError = () => setError(true);

  const updateLang = (lang) => {
    // change page lang
    i18n.changeLanguage(lang);

    // update router
    const path = window.location.pathname.split("/");
    path[1] = lang;
    window.history.pushState({}, undefined, path.join("/"));
  };

  useEffect(() => {
    try {
      const mainID =
        i18n.language === "ua"
          ? "8711b076-0228-4d38-bdc5-a23fb513f8e9"
          : "dc34f273-68ca-425a-9e58-0f2b6064b2f9";

      GetMainPageProjectDescr(mainID, i18n.language).then((result) =>
        setProjectDescr(result)
      );
    } catch (e) {
      console.error("first mage load err: ", e);
    }

    // check if url language is same as router
    const urlLang = window.location.pathname.split("/")[1];
    if (i18n.language !== urlLang) {
      updateLang(urlLang);
    }

    document.documentElement.lang = i18n.language;
    document.title = i18n.t("document_title");
  }, [i18n, i18n.language]);

  function setData(desc) {
    if (desc === null || desc === undefined) {
      return;
    }

    console.log("desc", desc);

    navigate(`${i18n.language}/desc/${replaceString(desc.id)}`); // add router
    setFullEventData(desc);
    return;
  }

  let data = null;
  let mainPage = (
    <MainPage
      isError={newError}
      projectDescr={projectDescr}
      setFullEventData={setData}
    />
  );

  if (ready) {
    data = (
      <Routes>
        <Route
          path="/"
          element={
            <Navigate to={`/${updateLangPath(i18n.language)}`} replace={true} />
          }
          replace
        />

        <Route path=":lang">
          <Route index element={mainPage} />
          <Route path={`about`} element={<About />} />
          <Route
            path={`projects`}
            element={FooterWrapper(<Projects setFullEventData={setData} />)}
          />
          <Route
            path={`desc`}
            onError={mainPage}
            element={<EventDescriptionScroll props={fullEventData} />}
          >
            <Route
              path={`*`}
              onError={mainPage}
              element={<EventDescriptionScroll props={fullEventData} />}
            />
          </Route>

          <Route path={`partners`} element={FooterWrapper(<Partners />)} />
          <Route path={`contacts`} element={FooterWrapper(<ContactForm />)} />
        </Route>
        <Route path="*" element={FooterWrapper(<Navigate to="/" replace />)} />
      </Routes>
    );

    return error ? (
      <OOPSError />
    ) : (
      <div id="data">
        <SEO
          description={`NGO Ukrainian Association of Culturologists-Lviv. The project of Ukrainian Association of Culturologists-Lviv NGO.
          Громадська організація Українська Асоціація Культурологів - Львів. 
          ГО «УАК - Львів», Українська асоціація культурологів Львів - громадська організація, яка з 2013 року взяла собі за мету здійснювати медіаторську, аналітичну, організаційну та промоційну діяльність в сфері культури та в усіх галузях, що входять в неї`}
          type={"article"}
        />

        <div id="header">
          <Header onLanguageChange={(lang) => updateLang(lang)} />
        </div>
        <Suspense>
          <div id="main">{data}</div>
        </Suspense>
      </div>
    );
  }
  return <FullSrcLoader />;
};

export default App;
