import { CardMedia, Box, Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetOneEvent } from "../../services/api/pages/pages";

import { FullSrcLoader } from "../../components/loader/pageLoader";
import { Footer } from "../../components/general/Footer";
import { Gallery } from "../../components/gallery/gallery";

import "../../css/eventDescr.css";

const EventDescription = ({ props, index, mediaTop = false }) => {
  const { title, subtitle, description, media } = props;

  const mediaComp = media !== undefined ? <Gallery media={media} /> : null;
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100%",
      }}
      key={`descr_` + index}
    >
      {/* content */}
      <Box
        sx={{
          width: { xs: "80vw", sm: "75vw", md: "70vw" },
          backgroundColor: "white",
          padding: "2rem",
          margin: "2vh auto",
          borderRadius: "1rem",
          border: "2px solid white",
          boxShadow:
            "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
        }}
      >
        {TitleComponent(title)}

        <Typography
          sx={{
            textAlign: "justify",
            fontSize: {
              xs: "0.8em",
              lg: "1.3em",
            },
            fontFamily: "Montserrat",
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: subtitle }} />
        </Typography>
        <Typography
          sx={{
            textAlign: "justify",
            fontSize: { xs: "0.9em", sm: "1em" },
            fontFamily: "Montserrat",
          }}
          variant="body2"
        >
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      </Box>

      {!mediaTop ? mediaComp : null}
    </Box>
  );
};

const TitleComponent = (titleText) => {
  return (
    <>
      <Typography
        sx={{
          fontSize: {
            xs: "0.8em",
            sm: "1.3em",
            md: "1.5em",
            lg: "1.8em",
            xl: "1.8em",
          },
          textAlign: "justify",
          textAlignLast: "center",
          fontWeight: "bold",
          fontFamily: "Montserrat",
          textTransform: "uppercase",
        }}
        variant="inherit"
      >
        <span dangerouslySetInnerHTML={{ __html: titleText }} />
      </Typography>
    </>
  );
};

const EventScroll = (props) => {
  const { main, events = [] } = props;

  return (
    <Box
      sx={{
        backgroundColor: "#8dc63f",
      }}
    >
      <Box
        sx={{
          marginBottom: "10vh",
          paddingTop: "5vh",
        }}
      >
        <EventDescription props={main} mediaTop={true} index={"start"} />
        {events.length !== 0
          ? events.map((event, index) => (
              <EventDescription props={event} index={index} />
            ))
          : null}
      </Box>
      <Footer />
    </Box>
  );
};

const Banner = ({ image, changeLoaderState }) => {
  return (
    <>
      <CardMedia
        component="img"
        src={image}
        onLoad={() => {
          changeLoaderState();
        }}
        sx={{
          width: "100vw",
          height: "auto",
          maxHeight: "50vh",
          objectFit: "content",
        }}
      />
    </>
  );
};

async function GetMainPageProjectDescr(pageID, language) {
  const response = await GetOneEvent({
    params: {
      lang: language,
      id: pageID,
    },
  }).catch((error) => {
    console.error(error);

    return null;
  });

  if (!!response) {
    if (!!response.data) {
      return response.data;
    }
  }
  return null;
}

const getIdAndLangFromPath = (path) => {
  const [lang, id] = path.split("/desc/");
  if (!!id) return [lang, id.replaceAll("/", "-")];
  return [null, null];
};

export default function EventDescriptionScroll({ props }) {
  const [isBannerLoading, setBannerLoader] = useState(true);
  const [description, setDescription] = useState(props);
  const [language, setLanguage] = useState(null);
  const { i18n } = useTranslation();

  const changeLoaderState = () => {
    setBannerLoader(!isBannerLoading);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // return to home page if something error started
    if (description === null || description === undefined) {
      const [lang, id] = getIdAndLangFromPath(window.location.pathname);
      if (!!id && !!lang) {
        try {
          GetMainPageProjectDescr(id, i18n.language).then((result) =>
            setDescription(result)
          );
        } catch (e) {
          return <Navigate to="/" replace />;
        }
      } else {
        return <Navigate to="/" replace />;
      }
    }
  }, [i18n, i18n.language]);

  if (language === null) {
    setLanguage(i18n.language);
  } else if (language !== i18n.language) {
    return <Navigate to={`/${i18n.language}/projects`} replace />;
  }

  if (i18n.language !== language) {
    return <Navigate to="/projects" replace />;
  }

  if (!!description) {
    return (
      <Box sx={{ overflow: "hidden" }}>
        {isBannerLoading ? <FullSrcLoader /> : null}
        <Box display={isBannerLoading ? "none" : "inherit"}>
          <Banner
            changeLoaderState={changeLoaderState}
            image={description.main.media[0].url}
          />
          {EventScroll(description)}
        </Box>
      </Box>
    );
  }
  return null;
}
