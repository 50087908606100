import { useState } from "react";
import LazyLoad from "react-lazy-load";

import {
  Box,
  CardMedia,
  Skeleton,
  ImageList,
  ImageListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

import "./style.css";

function checkColumnsNumber(arrayLength) {
  if (arrayLength <= 0) return 0;

  if (arrayLength % 4 === 0) return 4;
  if (arrayLength % 3 === 0) return 3;

  if (arrayLength === 1) return 1;
  if (arrayLength === 2) return 2;

  return 4;
}

function ChosemediaType({ media, addParams, isLoaded, endLoading }) {
  const { url, type } = media;
  const [imgSrc, setImgSrc] = useState(url);
  const errImage = "/images/errors/errorImage.png";

  const { changeFullScrState, isFullScr, columns, index } = addParams;

  const whenDisplayImage = {
    display: isLoaded ? "contain !important" : "none !important",
  };

  const fullScrImage = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  };

  const defGalleryImage = {
    height: columns === 1 ? "40vh" : "100%",
    width: isFullScr ? "auto" : `100%`,
  };

  const [count, setCount] = useState(isFullScr ? 1 : 10);

  const onErr = () => {
    setTimeout(() => {
      setCount(count - 1);
    }, process.env.REACT_APP_IMAGE_REPEAT_INT);
  };

  if (count <= 0 && imgSrc !== errImage) {
    setImgSrc(errImage);
  }

  const imageCurrentProps = isFullScr ? fullScrImage : defGalleryImage;

  switch (type) {
    case "video":
      return (
        <Box
          sx={{
            width: "100%",
            maxWidth: "750px",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: 0,
              paddingTop: "56.25% // 9 / 16 * 100%",
            }}
          >
            <CardMedia
              key={count}
              component="iframe"
              image={imgSrc}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              seamless
              allowFullScreen
              onClick={() => changeFullScrState(index)}
              onLoad={() => endLoading()}
              onError={() => onErr()}
            />
          </Box>
        </Box>
      );
    case "image":
      return (
        <CardMedia
          key={count}
          component="img"
          sx={{
            ...whenDisplayImage,
            ...imageCurrentProps,
          }}
          src={imgSrc}
          onClick={() => changeFullScrState(index)}
          onLoad={() => endLoading()}
          onError={() => onErr()}
        />
      );

    default:
      console.log(`No such element ???`);
      return null;
  }
}

function CardLoader({ media, addParams }) {
  const [loaded, setLoaded] = useState(false);

  const endLoading = () => setLoaded(!loaded);

  return (
    <>
      <LazyLoad threshold={0.5}>
        <ChosemediaType
          media={media}
          addParams={addParams}
          isLoaded={loaded}
          endLoading={endLoading}
        />
      </LazyLoad>
      <Skeleton
        className="media"
        sx={{
          display: loaded ? "none !important" : "flex !important",
          minHeight: addParams.columns === 1 ? "40vh" : "6vh",
        }}
        animation="wave"
      />
    </>
  );
}

export function FullScrGallery({
  media,
  changeFullScrState,
  initialSlide = 0,
}) {
  return (
    <Box>
      <Swiper
        slidesPerView={1}
        spaceBetween={100}
        initialSlide={initialSlide}
        loop={true}
        lazy
        pagination={{
          clickable: true,
        }}
        className="mainGallery"
        navigation={true}
        modules={[Pagination, Navigation]}
      >
        {media.map((mediaElement, index) => (
          <SwiperSlide key={"sl_" + index}>
            <CardLoader
              addParams={{
                changeFullScrState: changeFullScrState,
                columns: 1,
                index: index,
                isFullScr: true,
              }}
              media={mediaElement}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}

export function Gallery({ media }) {
  const [gallery, setGallery] = useState({
    open: false,
    index: 0,
  });

  const handleChangeState = (index = 0) => {
    setGallery({
      open: !gallery.open,
      index: index,
    });
  };

  const columnsNumber = checkColumnsNumber(media.length);

  return (
    <>
      {gallery.open ? (
        <Dialog
          open={gallery.open}
          keepMounted
          onClose={handleChangeState}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            style: {
              display: "contents",
            },
          }}
        >
          <DialogTitle sx={{ m: -1.5, p: 0, backgroundColor: "yellow" }}>
            <IconButton
              onClick={handleChangeState}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
                color: "white",
                backgroundColor: "#8dc63f",
              }}
            >
              <CloseIcon onClick={handleChangeState} fontSize="large" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <FullScrGallery
              media={media}
              changeFullScrState={handleChangeState}
              initialSlide={gallery.index}
            />
          </DialogContent>
        </Dialog>
      ) : null}

      <Box
        display="block"
        alignItems="center"
        justifyContent="center"
        margin={"auto"}
        sx={{
          width: { xs: "80vw", sm: "75vw", md: "70vw" },
          overflow: "hidden",

          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <ImageList
          variant="masonry"
          cols={columnsNumber}
          gap={8}
          sx={{
            overflowX: "hidden",
          }}
        >
          {media.map((mediaElement, index) => (
            <ImageListItem key={mediaElement.url}>
              <CardLoader
                addParams={{
                  changeFullScrState: handleChangeState,
                  columns: columnsNumber,
                  index: index,
                  isFullScr: false,
                }}
                media={mediaElement}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}
