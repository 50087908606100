import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import "../../css/footer.css";

function image(
  imgName,
  { width = "20vw", aspectRatio = "1/1", sacaleSize = "1" }
) {
  return (
    <React.Fragment>
      <Box
        sx={{
          height: "auto",
          width: width,
          aspectRatio: aspectRatio,
          backgroundImage: `url(/images/footer/${imgName})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          transform: {
            xs: `scale(${sacaleSize})`,
            md: `scale(${sacaleSize})`,
          },
        }}
      />
    </React.Fragment>
  );
}

function imageList() {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 8, sm: 2, md: 4 }}
      justifyContent="center"
      alignItems="center"
    >
      {image("logo0.png", {
        width: {
          xs: "35vw",
          md: "10vw",
        },
        sacaleSize: 1.2,
      })}

      {image("logo2.png", {
        width: {
          xs: "30vw",
          md: "10vw",
        },
        aspectRatio: "16/9",
      })}
      {image("logo3.png", {
        width: {
          xs: "35vw",
          md: "10vw",
        },
        aspectRatio: "16/9",
      })}
      {image("logo4.png", {
        width: {
          xs: "40vw",
          md: "10vw",
        },
        sacaleSize: 0.5,
      })}
    </Stack>
  );
}

function links() {
  return (
    <div>
      <a
        href="https://www.youtube.com/channel/UChrQZVhFlOmblPV8-xWIJfA"
        target="_blank"
        rel="noreferrer"
        className="youtube social"
      >
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>
      <a
        href="https://www.facebook.com/uaclviv"
        target="_blank"
        rel="noreferrer"
        className="facebook social"
      >
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a
        href="https://www.instagram.com/uac_lviv/"
        target="_blank"
        rel="noreferrer"
        className="instagram social"
      >
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
    </div>
  );
}

function add() {
  return (
    <div
      className="desc"
      style={{
        color: "black",
        marginRight: "2vw",
        textDecoration: "underline",
      }}
    >
      <Typography
        align="center"
        variant="body1"
        sx={{
          fontSize: { xs: "0.7em", md: "1em", xl: "1em" },
          fontFamily: "Montserrat",
          textTransform: "capitalize",
        }}
      >
        © 2013-2023 Українська Асоціація Культурологів - Львів
      </Typography>
    </div>
  );
}

function contactRow() {
  return (
    <React.Fragment>
      <Stack
        width="100%"
        direction={{ xs: "column", sm: "column", md: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent={{ xs: "center", md: "space-between" }}
        alignItems={{ xs: "center", md: "flex-end" }}
      >
        {links()}
        {add()}
      </Stack>
    </React.Fragment>
  );
}

function disclaimer() {
  return (
    <Box
      sx={{
        display: "flex",
        overflow: "none",
        backgroundColor: "#8dc63f",
      }}
    >
      <Typography
        align="center"
        sx={{
          width: { xs: "95vw", sm: "80vw" },
          margin: { xs: "30px auto 30px auto", sm: "30px auto 30px auto" },
          fontSize: { xs: "1em" },
          fontWeight: "bold",
        }}
        variant="hi"
      >
        The project “EU4ResilientRegions – Special Assistance Programme Ukraine”
        is co-financed by the European Union and the German Federal Ministry for
        Economic Cooperation and Development (BMZ) and is implemented by the
        Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH.
      </Typography>
    </Box>
  );
}

function FooterElem() {
  return (
    <Box
      component={"footer"}
      sx={{
        position: "relative",
        backgroundColor: "white",
        height: "100%",
        width: "100vw",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2.2}
      >
        <Grid item xs={12}>
          {imageList()}
        </Grid>
        <Grid item xs={12}>
          {contactRow()}
        </Grid>
        <Grid item xs={12}>
          {disclaimer()}
        </Grid>
      </Grid>
    </Box>
  );
}

export const Footer = () => {
  return (
    <>
      <Box
        sx={{
          width: { xs: "100vw", sm: "auto" },
          height: { xs: "100%" },
        }}
      >
        {FooterElem()}
      </Box>
    </>
  );
};

export const FooterWrapper = (children) => {
  return (
    <>
      {children}
      <Footer />
    </>
  );
};
