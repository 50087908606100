import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const titles = {
  ua: "ГО «УАК - Львів», Українська асоціація культурологів Львів",
  en: 'NGO "UAC-Lviv", Ukrainian Association of Cultural Studies Lviv',
};

const keyWords = {
  ua: "громадська організація, культуролог, культурологія, львів, уак, го",
  en: "ngo, goverment organization, culturologist, culture, lviv, uak",
};

export function SEO({ title = "", description, type }) {
  const { i18n } = useTranslation();

  return (
    <Helmet>
      <title>{title !== "" ? title : titles[i18n.language]}</title>
      {/* Standard metadata tags */}
      <meta name="description" content={description} />
      <meta name="keywords" content={keyWords[i18n.language]} />

      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:description" content={description} />
      {/* End Facebook tags */}

      {/* Twitter tags */}
      <meta name="twitter:card" content={type} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
    </Helmet>
  );
}
