// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  a.desc {
    height: auto;
    margin-right: 2vw;
  }

  a.social {
    margin: 1rem;
    transition: transform 250ms;
    display: inline-block;
  }

  a.social:hover {
    transform: translateY(-10px);
  }

  a.youtube {
    color: #eb3223;
  }
  
  a.facebook {
    color: #4968ad;
  }
  
  a.twitter {
    color: #49a1eb;
  }
  
  a.instagram {
    color: black;
  }


`, "",{"version":3,"sources":["webpack://./src/css/footer.css"],"names":[],"mappings":";EACE;IACE,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,2BAA2B;IAC3B,qBAAqB;EACvB;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,YAAY;EACd","sourcesContent":["\n  a.desc {\n    height: auto;\n    margin-right: 2vw;\n  }\n\n  a.social {\n    margin: 1rem;\n    transition: transform 250ms;\n    display: inline-block;\n  }\n\n  a.social:hover {\n    transform: translateY(-10px);\n  }\n\n  a.youtube {\n    color: #eb3223;\n  }\n  \n  a.facebook {\n    color: #4968ad;\n  }\n  \n  a.twitter {\n    color: #49a1eb;\n  }\n  \n  a.instagram {\n    color: black;\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
