// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .MuiSkeleton-root.GalleryCard {
    display: flex;

    width: 100%;
    height: 100%;
    margin:auto;
  }

  .MuiCardMedia-root.MediaImage {
    min-height: 40vh;
    max-height: 40vh;

    max-width: 90vw;

    padding-top: 2vh;

    aspect-ratio: 16/9;
    margin: auto;
  }

  .MuiCardMedia-root.GalleryVideoM {
    width: 100%;
    height: 40vh;
  }

  .MuiCardMedia-root.GalleryImage {
    max-width: 90vw;

    aspect-ratio: 16/9;
    margin: auto;
  }`, "",{"version":3,"sources":["webpack://./src/css/eventDescr.css"],"names":[],"mappings":";EACE;IACE,aAAa;;IAEb,WAAW;IACX,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;;IAEhB,eAAe;;IAEf,gBAAgB;;IAEhB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,eAAe;;IAEf,kBAAkB;IAClB,YAAY;EACd","sourcesContent":["\n  .MuiSkeleton-root.GalleryCard {\n    display: flex;\n\n    width: 100%;\n    height: 100%;\n    margin:auto;\n  }\n\n  .MuiCardMedia-root.MediaImage {\n    min-height: 40vh;\n    max-height: 40vh;\n\n    max-width: 90vw;\n\n    padding-top: 2vh;\n\n    aspect-ratio: 16/9;\n    margin: auto;\n  }\n\n  .MuiCardMedia-root.GalleryVideoM {\n    width: 100%;\n    height: 40vh;\n  }\n\n  .MuiCardMedia-root.GalleryImage {\n    max-width: 90vw;\n\n    aspect-ratio: 16/9;\n    margin: auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
