import { Box } from "@mui/material";

import "./pageLoader.css";

function BookLoader() {
  return (
    <div className="book">
      <div className="inner">
        <div className="left"></div>
        <div className="middle"></div>
        <div className="right"></div>
      </div>
      <ul>
        {[...Array(18)].map((x, i) => (
          <li key={i} />
        ))}
      </ul>
      <p className="text"> Loading...</p>
    </div>
  );
}

export function FullSrcLoader() {
  return (
    <Box
      sx={{
        height: "90vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#8dc63f",
        overflow: "hidden",
      }}
    >
      <BookLoader />
    </Box>
  );
}
