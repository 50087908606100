// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  background: linear-gradient(#34559a, darkblue);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.error {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 60%;
}
.error svg {
  display: block;
  width: 100%;
  height: auto;
  fill: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/components/error/oops/style.scss"],"names":[],"mappings":"AAGA;EACE,8CAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,uBAAA;EACA,qBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;AAFF;;AAKA;EACE,QAAA;EACA,cAAA;EACA,gBAAA;EACA,UAAA;AAFF;AAIE;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,UAzBQ;AAuBZ","sourcesContent":["$primarycolor :#34559a;\n$standout : #FFF;\n\n.page {\n  background: linear-gradient($primarycolor, darkblue);\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100vw;\n}\n\n.error {\n  order: 0;\n  flex: 0 1 auto;\n  align-self: auto;\n  width: 60%;\n\n  svg {\n    display: block;\n    width: 100%;\n    height: auto;\n    fill: $standout;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
