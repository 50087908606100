import { Client } from "../connect/config";

export function GetAllEvents(config) {
    return Client.get("/event", config);
}

export function GetOneEvent(config) {
    return Client.get("/event/unique", config);
}

