// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDialog-root.FullScrGallery {
    width: 100%;
    height: 40vh;
}


.mainGallery.swiper {
    width: 100vw;
    height: 80vh;
  }
  
.mainGallery .swiper-slide {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: flex;
  justify-content: center;
  justify-items: center;
}


.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.mainGallery .is-visible {
  width: 100%;
  max-width: 70vw;
}

.is-visible {
  opacity: 1;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/gallery/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,YAAY;EACd;;AAEF;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,qBAAqB;AACvB;;;AAGA;EACE,UAAU;EACV,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,YAAY;AACd","sourcesContent":[".MuiDialog-root.FullScrGallery {\n    width: 100%;\n    height: 40vh;\n}\n\n\n.mainGallery.swiper {\n    width: 100vw;\n    height: 80vh;\n  }\n  \n.mainGallery .swiper-slide {\n  flex-shrink: 0;\n  height: 100%;\n  position: relative;\n  transition-property: transform;\n  display: flex;\n  justify-content: center;\n  justify-items: center;\n}\n\n\n.LazyLoad {\n  opacity: 0;\n  transition: all 1s ease-in-out;\n}\n\n.mainGallery .is-visible {\n  width: 100%;\n  max-width: 70vw;\n}\n\n.is-visible {\n  opacity: 1;\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
