// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  #header {
    position: relative;
    margin: max(11vh, 100px);

  }

  #main{
    position: absolute;
    min-height: 90vh;
    min-width: 100vw;
  }`, "",{"version":3,"sources":["webpack://./src/css/app.css"],"names":[],"mappings":"EAAE;IACE,kBAAkB;IAClB,wBAAwB;;EAE1B;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;EAClB","sourcesContent":["  #header {\n    position: relative;\n    margin: max(11vh, 100px);\n\n  }\n\n  #main{\n    position: absolute;\n    min-height: 90vh;\n    min-width: 100vw;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
